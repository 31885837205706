import { useRouter } from 'next/router';
import { message } from '@vette/frontend-utils';
import { Button } from '@vette/ui-components';
import { DashboardCard } from './DashboardCard';
import { useVetterDashboardQuery } from '@vette/data-access';

import * as S from './styles';

type Props = {
  userId: string;
};

export const Dashboard = ({ userId }: Props) => {
  const router = useRouter();
  const { data, loading } = useVetterDashboardQuery({
    variables: { userId },
    onError: () => {
      message.error(
        'Failed to obtain information about your dashboard. Please, make sure your network is reliably working.'
      );
    },
    onCompleted: data => {
      if (data?.vetterDashboard?.__typename === 'User') {
        const { onboardingCompleted, approvalStatus } = data.vetterDashboard;

        const isApproved = approvalStatus === 'ACTIVE';

        if (!onboardingCompleted) {
          router.replace('/terms');
        } else if (!isApproved) {
          message.destroy();
          message.info(
            'You are on the waitlist to become a Vetter. We will inform you once your account is approved.',
            0
          );
        }
      }
    },
  });

  const allVetsCount = data?.vetterDashboard?.allVetsCount;
  const earnings = data?.vetterDashboard?.stripeUser?.credit;
  const stripeUrl = data?.vetterDashboard?.stripeUser?.stripeDashboardUrl;

  return (
    <S.Wrapper className="dashboard-vetter">
      <S.Heading level={1}>Dashboard</S.Heading>
      <S.Cards>
        <DashboardCard
          loading={loading}
          label="All-time Vettes"
          src="/images/illustration-vette.svg"
          backgroundSize="contain"
        >
          <S.PrimaryText>
            {allVetsCount != null ? allVetsCount : '-'}
          </S.PrimaryText>
        </DashboardCard>

        <DashboardCard
          loading={loading}
          label="This week's earnings"
          src="/images/illustration-earnings.svg"
          tooltip="Cash is transferred to Stripe account and available for withdrawal every Friday."
          backgroundSize="contain"
        >
          <S.PrimaryText>{`$${earnings != null ? earnings : 0}`}</S.PrimaryText>
        </DashboardCard>
        <DashboardCard
          loading={loading}
          label="More metrics"
          src="/images/stripe-logo.svg"
          imageOffsetY="70%"
        >
          <a
            href={stripeUrl ?? '/payouts'}
            target={stripeUrl ? '_blank' : undefined}
            rel="noreferrer"
          >
            <Button size="large" actionType="primary">
              {stripeUrl
                ? 'See directly in Stripe'
                : 'Set up your Stripe account'}
            </Button>
          </a>
        </DashboardCard>
      </S.Cards>
    </S.Wrapper>
  );
};
