import styled from 'styled-components';
import { Text, Title } from '@vette/ui-components';
import { Skeleton } from 'antd';

export const Wrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  padding: 36px;
  overflow-y: auto;
  z-index: 1;
`;

export const Heading = styled(Title)`
  padding: 48px;

  && {
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const Cards = styled.div`
  display: flex;
  flex: 1;
  gap: 36px;
  margin-top: 24px;
  margin-bottom: 50px;
  max-height: 600px;
  height: 100%;
`;

export const Card = styled.div`
  display: flex;
  flex: 1;
  max-width: 400px;
  flex-direction: column;
  border-radius: 18px;
  background: ${({ theme }) => theme.colors.white};
  position: relative;
  align-items: center;
  justify-content: flex-end;
`;

export const PrimaryText = styled(Title)`
  && {
    font-size: 64px;
    line-height: 120%;
    color: ${({ theme }) => theme.colors.blue60};
  }
  margin-top: 9px;
  text-align: center;
`;

export const Label = styled(Text)`
  color: ${({ theme }) => theme.colors.grey100};
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  text-align: center;
`;

export const CardBody = styled.div`
  padding: 48px;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  height: 210px;
  display: flex;
`;

export const ImageWrap = styled.div<{
  $src: string;
  offsetY?: string;
  backgroundSize?: 'contain';
}>`
  flex: 1;
  width: 100%;
  background: url(${({ $src }) => $src}) no-repeat center center;
  ${({ offsetY }) => `background-position-y: ${offsetY}`};
  ${({ backgroundSize }) => `background-size: ${backgroundSize}`};
`;

export const SkeletonCard = styled(Skeleton).attrs({
  title: true,
  paragraph: false,
  active: true,
})`
  flex: 1;
  display: flex;
  max-width: 400px;
  .ant-skeleton-title {
    border-radius: 18px;
    height: 100%;
    display: flex;
    flex: 1;
    margin-top: 0;
  }
`;
