import { Tooltip } from 'antd';
import React from 'react';
import * as S from './styles';

type Props = React.PropsWithChildren<{
  src: string;
  label: string;
  imageOffsetY?: string;
  loading: boolean;
  tooltip?: string;
  backgroundSize?: 'contain';
}>;

export const DashboardCard: React.FC<Props> = ({
  src,
  label,
  imageOffsetY,
  tooltip,
  children,
  loading,
  backgroundSize,
}) => {
  const body = <S.Label>{label}</S.Label>;
  return (
    <S.SkeletonCard active loading={loading}>
      <S.Card>
        <S.ImageWrap
          $src={src}
          offsetY={imageOffsetY}
          backgroundSize={backgroundSize}
        />
        <S.CardBody>
          {tooltip ? <Tooltip title={tooltip}>{body}</Tooltip> : body}
          {children}
        </S.CardBody>
      </S.Card>
    </S.SkeletonCard>
  );
};
